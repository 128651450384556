<script setup>
/* eslint-disable */
import { ref, watch, onMounted } from "vue";
import Slider from "@vueform/slider";
import Swal from "sweetalert2";
import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import animationData from "@/components/widgets/msoeawqm.json";
import i18n from "../../i18n";
import store from "@/state/store";
import router from "../../router/index";
import axios from "axios";
import categories from "@/components/categories"
import { SimpleBar } from "simplebar-vue3";

String.prototype.slugify = function () {
  var text = this;
  var trMap = {
    çÇ: "c",
    ğĞ: "g",
    şŞ: "s",
    üÜ: "u",
    ıİ: "i",
    öÖ: "o",
  };
  for (var key in trMap) {
    text = text.replace(new RegExp("[" + key + "]", "g"), trMap[key]);
  }
  return text
    .replace(/[^-a-zA-Z0-9\s]+/gi, "")
    .replace(/\s/gi, "-")
    .replace(/[-]+/gi, "-")
    .toLowerCase();
};

const { t } = i18n.global;
const title = t("Ürünler");

const loading = ref(false)

const items = [
  {
    text: "Elektro Portal",
    href: "/",
  },
  {
    text: t("Ürünler"),
    active: true,
  },
];

const pages = ref([]);
const page = ref(1);
const perPage = ref(15);

function paginate(productsData) {
  let from = page.value * perPage.value - perPage.value;
  let to = page.value * perPage.value;
  return productsData.slice(from, to);
}

function setPages(data) {
  page.value = 1
  pages.value = []
  let numberOfPages = Math.ceil(data.length / perPage.value);
  for (let index = 1; index <= numberOfPages; index++) {
    pages.value.push(index);
  }
}

const Kategoriler = ref({})

axios.get(process.env.VUE_APP_B2B_API_URL+'category/getCategories').then((data) => {
    Kategoriler.value = data['data']
}).catch((error) => {
  console.log('Bir hata oluştu')
  console.log(error)
})

const searchQuery = ref("")
const brandSearch = ref("")
const Markalar = ref([])
const Brands = ref([])
axios.get(process.env.VUE_APP_B2B_API_URL+'/brand/getBrands').then((data) => {
    Brands.value = data['data']
    Markalar.value = Brands.value
}).catch((error) => {
  console.log('Bir hata oluştu')
  console.log(error)
})

const MarkaLimit = ref(5);

function LoadBrand() {
  let AddBrandLimit = 5;
  if (Markalar.value.length - MarkaLimit.value < 5)
    AddBrandLimit = Markalar.value.length - MarkaLimit.value;
  MarkaLimit.value = MarkaLimit.value + AddBrandLimit;
}

const defaultOptions = {
  animationData: animationData,
};

const AllproductsData = ref([]);

const productsData = ref([])

function minusValue(index) {
  if (productsData.value[((page.value-1)*perPage.value) + index].value > 1) productsData.value[((page.value-1)*perPage.value) + index].value--;
}

function plusValue(index) {
  productsData.value[((page.value-1)*perPage.value) + index].value++;
}
function addCartProduct(index) {
  let proData = productsData.value[((page.value - 1) * perPage.value) + index]
  store.dispatch("cart/addProduct", {value: proData}).then((status)=>{
    if(status){
      console.log(proData.value, proData.name)
      Swal.fire({
        title: t("Belgeye Eklendi!"),
        html: t("Belgeye_x_urun_eklendi", { quantity: proData['value'], name: proData['name'] }),
        icon: "success",
        confirmButtonText: t("Sepete Git"),
        cancelButtonText: t("Alışverişe Devam Et"),
        focusCancel: true,
        showCancelButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          router.push({ path: "/sepet" });
        }
      });
      store.commit('cart/iasRefresh')
      proData.value = proData.quantity
    } else {
      Swal.fire({
        title: t("Sepete Eklenemedi!"),
        html: t("Bir hata oluştu, lütfen tekrar deneyiniz."),
        icon: "error",
        confirmButtonText: t("Alışverişe Devam Et"),
      })
    }
  });
}

const SelectedFilter = ref({'brand' : [], 'category' : { 'id' : '', 'name' : ''}})

function SelectBrand(index){
  SelectedFilter.value['brand'].push({key: Markalar.value[index].markaid, value: Markalar.value[index].marka })
  Brands.value.splice(Brands.value.findIndex((brand) => brand.marka == Markalar.value[index].marka), 1)
  Markalar.value.splice(index, 1)
}

function RemoveFilter(index){
  Brands.value.push({ markaid: SelectedFilter.value['brand'][index].key, marka: SelectedFilter.value['brand'][index].value})
  if(SelectedFilter.value['brand'][index].value.toUpperCase().includes(brandSearch.value.toUpperCase())){
    Markalar.value.push({ markaid: SelectedFilter.value['brand'][index].key, marka: SelectedFilter.value['brand'][index].value})
  }
  SelectedFilter.value['brand'].splice(index, 1)
}

function RemoveCategory(){
  SelectedFilter.value['category']['name'] = ''
}

function searchProducts(event){
  loading.value = true
  productsData.value = []
  if(event !== undefined) event.target.blur(); 

  let brand = ''
  if(SelectedFilter.value.brand.length > 0){
    brand = JSON.stringify(SelectedFilter.value.brand)
  }
  // console.log(SelectedFilter.value.category.id)
  let formData = new FormData();
  formData.append('name', searchQuery.value)
  formData.append('category', SelectedFilter.value['category']['id'])
  formData.append('brand', brand)
  axios.post(process.env.VUE_APP_B2B_API_URL+'product/getProduct', formData).then((data) => {
      if (data.data['error'] != undefined){
        loading.value = false
      } else {
        productsData.value = []
        data.data.forEach(product => {
          productsData.value.push({
            id: product.material,
            image: product.imgURL,
            name: product.isim,
            productCode: product.vendrefnum,
            brand: product.markaidadi,
            category: product.stext,
            listePrice: product.liste,
            price: product.webfiyat,
            vatrate: product.salvatkey,
            lcurrency: product.lcurrency,
            value: 1,
            quantity: 1
          })
        });
        loading.value = false
        setPages(productsData.value);
      }
  }).catch((error) => {
    console.log('Bir hata oluştu')
    console.log(error)
  })
}

function getBestProducts(){
  let formData = new FormData();
  axios.post(process.env.VUE_APP_B2B_API_URL+'product/getBestProducts', formData).then((data) => {
    if (data.data['error'] != undefined){
      loading.value = false
    } else {
      productsData.value = []
      console.log(data)
      data.data.products.forEach(product => {
        productsData.value.push({
          id: product.material,
          image: product.imgURL,
          name: product.isim,
          productCode: product.vendrefnum,
          brand: product.markaidadi,
          category: product.stext,
          listePrice: product.liste,
          price: product.webfiyat,
          vatrate: product.salvatkey,
          lcurrency: product.lcurrency,
          value: 1,
          quantity: 1
        })
      });
      loading.value = false
      setPages(productsData.value);
    }
  }).catch((error) => {
    console.log('Bir hata oluştu')
    console.log(error)
  })
}

watch(
  () => SelectedFilter,
  (newValue) => {
    productsData.value = []
    pages.value = []
    newValue.value['brand'].forEach(filter => {
      let searchPro = AllproductsData.value.find((product) => (product.brand==filter.value && product.category == SelectedFilter.value['category']['name']))
      if( searchPro != undefined){
        productsData.value.push(searchPro)
      }
      console.log(filter.value)
    })
  },
  { deep: true }
)
watch(
  () => brandSearch,
  (query) => {
    Markalar.value = Brands.value.filter((brand) => brand.marka.toUpperCase().includes(query.value.toUpperCase()))
  },
  { deep: true }
)

function callback(x, y){
  SelectedFilter.value['category']['id'] = x
  SelectedFilter.value['category']['name'] = y
}

const showResults = ref(false)

function showResult(){
  if(searchQuery.value.length > 2){
    showResults.value = true
  } else {
    showResults.value = false
  }
}
function displayResult(x){
  setTimeout(() => {
    showResults.value = x
  }, 250);
}

let timeout = null

const liveResult = ref({ 'data' : [] })
const lastSearch = ref([])
const liveSearchLoading = ref(false)

function livesearch(){

  showResult()

  if(timeout){
    clearTimeout(timeout)
  }

  if(searchQuery.value.length > 2){

    timeout = setTimeout(() => {

      liveResult.value = { 'data' : [] }

      liveSearchLoading.value = true

      let brand = ''
      if(SelectedFilter.value.brand.length > 0){
        brand = JSON.stringify(SelectedFilter.value.brand)
      }
      let formData = new FormData();
      formData.append('name', searchQuery.value)
      formData.append('category', SelectedFilter.value['category']['id'])
      formData.append('brand', brand)

      axios.post(process.env.VUE_APP_B2B_API_URL+'product/liveSearch', formData).then((data) => {
        if(lastSearch.value.indexOf(searchQuery.value) == -1){
          lastSearch.value.push(searchQuery.value)
        }
        liveResult.value = data.data
        liveSearchLoading.value = false
      }).catch((error) => {
        console.log('Bir hata oluştu')
        console.log(error)
      })
    }, 500);
  }
}

function clickSearch(x){
  searchQuery.value = x
  searchProducts()
  console.log(x)
}

onMounted(() => {
  getBestProducts()
})

</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-xl-3 col-lg-4">
        <div class="card">
          <div class="accordion accordion-flush">
            <div class="card-body border-bottom">
              <p class="text-muted text-uppercase fs-12 fw-medium mb-2">
                {{ $t("Kategoriler") }}
              </p>
              <categories :data="Kategoriler" :main="true" @selectCategory="callback"></categories>
              
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header" id="flush-headingBrands">
                <button
                  class="accordion-button bg-transparent shadow-none"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseBrands"
                  aria-expanded="true"
                  aria-controls="flush-collapseBrands"
                >
                  <span class="text-muted text-uppercase fs-12 fw-medium"
                    >{{ $t("Markalar") }}</span
                  >
                  <span class="badge bg-success rounded-pill align-middle ms-1"
                    >{{ Markalar.length }}</span
                  >
                </button>
              </h2>

              <div
                id="flush-collapseBrands"
                class="accordion-collapse collapse show"
                aria-labelledby="flush-headingBrands"
              >
                <div class="accordion-body text-body pt-0">
                  <div class="search-box search-box-sm">
                    <input
                      v-model="brandSearch"
                      type="text"
                      class="form-control bg-light border-0"
                      :placeholder="t('Marka Ara...')"
                    />
                    <i class="ri-search-line search-icon"></i>
                  </div>
                  <div class="d-flex flex-column gap-2 mt-3">
                    <div
                      class="form-check"
                      v-for="(Marka, mID) in Markalar.slice(0, MarkaLimit)"
                      :key="Marka.markaid"
                    >
                      <input
                        class="form-check-input"
                        type="checkbox"
                        @click="SelectBrand(mID)"
                        :id="Marka.markaid"
                      />
                      <!-- list-group-item-warning -->
                      <label class="form-check-label" :for="Marka.markaid">{{
                        Marka.marka
                      }}</label>
                    </div>

                    <div v-if="Markalar.length - MarkaLimit > 0">
                      <button
                        type="button"
                        @click="LoadBrand"
                        class="
                          btn btn-link
                          text-decoration-none text-uppercase
                          fw-medium
                          p-0
                        "
                      >
                        {{ $t("marka_daha", { count: Markalar.length - MarkaLimit }) }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-9 col-lg-8">
        <div class="card">
          <div class="card-header border-0">
            <div class="row g-4">
              <div class="col-lg-10">
                <div class="app-search">
                  <div class="position-relative">
                    <input type="text" class="form-control" placeholder="Ürün Ara..." autocomplete="off" id="search-options"
                      @keyup="livesearch" @keypress.enter="searchProducts"
                      v-model="searchQuery" @focus="showResult" @focusout="displayResult(false)" />
                    <span class="mdi mdi-magnify search-widget-icon"></span>
                    <span class="
                        mdi mdi-close-circle
                        search-widget-icon search-widget-icon-close
                      "
                      :class="{'d-none':!showResults}"
                      ></span>
                  </div>
                  <div class="dropdown-menu dropdown-menu-lg" style="width: 80%" :class="{'show':showResults}">
                    <SimpleBar data-simplebar style="max-height: 320px;">
                      <!-- item-->
                      <div class="dropdown-header">
                        <h6 class="text-overflow text-muted mb-0 text-uppercase">
                          Son aramalarınız
                        </h6>
                      </div>

                      <div class="dropdown-item bg-transparent text-wrap">
                        <button @click="searchQuery = search; livesearch()" class="btn btn-soft-secondary btn-sm btn-rounded me-2 mb-2" v-for="search in lastSearch">{{ search }}<i
                            class="mdi mdi-magnify ms-1"></i></button>
                      </div>

                      <!-- item-->
                      <div class="dropdown-header mt-2">
                        <h6 class="text-overflow text-muted mb-2 text-uppercase">
                          Ürünler
                        </h6>
                      </div>
                      
                      <div v-if="!liveSearchLoading">
                        <div class="notification-list" v-if="liveResult.data.length > 0">
                          
                          <a href="javascript:void(0);" class="d-flex dropdown-item notify-item py-2 liveProducts" v-for="product in liveResult.data" :key="i" @click="clickSearch(product.proCode)">
                            <img :src="'https://elektromarketim.com/'+product.proIMG" class="me-3 rounded-circle avatar-xs"
                              alt="user-pic" />
                            <div class="flex-1">
                              <h6 class="m-0">{{ product.proName }}</h6>
                              <span class="fs-11 mb-0 text-muted">Ürün Kodu : {{ product.proCode }} | Marka : {{ product.proBrand }} | <b>Fiyat : {{ $filters.currency(product.price) }}</b></span>
                            </div>
                          </a>

                        </div>
                        <div class="notification-list" v-else>
                          <a href="javascript:void(0);" class="d-flex dropdown-item notify-item py-2 liveProducts">
                            <div class="flex-1">
                              <h6 class="m-0">Ürün Bulunamadı!</h6>
                            </div>
                          </a>
                        </div>
                      </div>
                      <div class="notification-list placeholder-glow" v-else>
                        
                        <a href="javascript:void(0);" class="d-flex dropdown-item notify-item py-2 liveProducts" v-for="i in 3" :key="i">
                          <div class="flex-shrink-0" style="width: 50px; height: 50px;">
                            <span class="placeholder w-100 h-100"></span>
                          </div>
                          <div class="flex-grow-1 ms-3">
                            <h6 class="m-0"><span class="placeholder col-12 w-100"></span></h6>
                            <span class="fs-11 mb-0 text-muted"><span class="placeholder col-12 w-75"></span></span>
                          </div>
                        </a>

                      </div>
                    </SimpleBar>
                  </div>
                </div>
              </div>
              <div class="col-lg-2">
                <div>
                  <button
                    class="btn btn-success"
                    style="width: 100%"
                    @click="searchProducts"
                    ><i class="ri-search-line align-bottom me-1"></i> {{ $t("Ara") }}</button
                  >
                </div>
              </div>
              <div class="col-lg-12">
                <div class="d-flex flex-wrap gap-2">
                  <b-badge variant="success" v-if="SelectedFilter['category']['name'] != ''"><i class="mdi mdi-close" @click="RemoveCategory()"></i>{{ SelectedFilter['category']['name'] }}</b-badge>
                  <b-badge variant="primary" v-for="(Filter, fID) in SelectedFilter['brand']" :key="Filter.key"><i class="mdi mdi-close" @click="RemoveFilter(fID)"></i> {{ Filter.value }}</b-badge>
                </div>
              </div>
            </div>
          </div>
          <div class="card-header" style="padding: 0; margin: 0;" v-show="productsData.length > 0">
            <div class="d-flex justify-content-end m-3">
              <div class="pagination-wrap hstack gap-2">
                  <a
                    class="page-item pagination-prev disabled"
                    href="javascript:;"
                    @click="page = 1"
                  >
                    <i class="bx bx-chevrons-left"></i>
                  </a>
                  <a
                    class="page-item pagination-prev disabled"
                    href="javascript:;"
                    @click="page != 1 ? page-- : null"
                  >
                    <i class="bx bx-chevron-left"></i>
                  </a>
                  <ul class="pagination listjs-pagination mb-0">
                    <li
                      v-for="(pageNumber, index) in pages.slice(
                        page - (page == 1 ? 1 : (page == pages.length) ? 3 : 2),
                        page + (page == 1 ? 2 : 1))"
                      :key="index"
                      @click="page = pageNumber"
                      :class="{
                        active: pageNumber == page,
                        disabled: pageNumber == '...',
                      }"
                    >
                      <a class="page" href="javascript:;">{{ pageNumber }}</a>
                    </li>
                  </ul>
                  <a
                    class="page-item pagination-next"
                    href="javascript:;"
                    @click="page != pages.length ? page++ : null"
                  >
                    <i class="bx bx-chevron-right"></i>
                  </a>
                  <a
                    class="page-item pagination-next"
                    href="javascript:;"
                    @click="page = pages.length"
                  >
                    <i class="bx bx-chevrons-right"></i>
                  </a>
                </div>
            </div>
          </div>
          <div class="card-body">
            <div class="tab-content text-muted">
              <div class="tab-pane active" id="productnav-all" role="tabpanel">
                <div class="row">
                  <div class="col-xl-12">
                      <ul class="products" v-if="productsData.length > 0">
                        <li class="products__item col-lg-4" v-for="(data, index) of paginate(productsData)" :key="index">
                          <div class="card product w-100">
                            <div class="card-body">
                              <div class="d-flex flex-row">
                                <div class="flex-shrink-0">
                                  <div class="avatar-lg p-1">
                                    <img
                                      :src="data.image"
                                      alt=""
                                      class="img-fluid d-block"
                                    />
                                  </div>
                                </div>
                                <div class="flex-grow-1 ms-3">
                                  <h5 class="fs-15">
                                    <a href="javascript:;" class="text-dark" style="word-break: break-word;">
                                      {{ data.name }}
                                    </a>
                                  </h5>
                                  <ul class="list-inline text-muted">
                                    <li class="list-inline-item">
                                      {{ $t("Ürün Kodu") }} : <span class="fw-medium">{{ data.productCode }}</span>
                                    </li>
                                    <li class="list-inline-item">
                                      {{ $t("Marka") }} : <span class="fw-medium">{{ data.brand }}</span>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                            <div class="card-footer">
                              <div class="d-flex align-items-end align-content-center">
                                <div class="flex-grow-1">
                                  <div class="text-center">
                                    <p class="text-muted mb-1">Liste Fiyatı</p>
                                    <h5 class="fs-15">
                                      <span id="ticket_price" class="product-price">{{ $filters.currency(data.listePrice, data.lcurrency) }}</span>
                                    </h5>
                                  </div>
                                </div>
                                <div class="flex-grow-1">
                                  <div class="text-center">
                                    <p class="text-muted mb-1">Site Fiyatı</p>
                                    <h5 class="fs-15">
                                      <span id="ticket_price" class="product-price">{{ $filters.currency(data.price) }}</span>
                                    </h5>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="card-footer">
                              <div class="d-flex align-items-end align-content-center">
                                <div class="flex-grow-1">
                                  <div class="d-flex flex-wrap my-n1">
                                    <div>
                                      <div class="input-step">
                                        <button
                                          type="button"
                                          class="minus"
                                          @click="minusValue(index)"
                                        >
                                          –
                                        </button>
                                        <input
                                          type="number"
                                          inputmode="numeric"
                                          class="product-quantity"
                                          v-model="data.value"
                                          min="1"
                                          max="100"
                                        />
                                        <button
                                          type="button"
                                          class="plus"
                                          @click="plusValue(index)"
                                        >
                                          +
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="flex-grow-1">
                                  <div class="d-flex align-items-center justify-content-end gap-2 text-muted">
                                    <button
                                        class="btn btn-success btn-sm"
                                        @click="addCartProduct(index)"
                                      >
                                        <i
                                          class="ri-shopping-cart-line align-bottom me-1"
                                        ></i>
                                        {{ $t("Ekle") }}
                                      </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                      <ul class="products placeholder-glow" v-if="loading">
                        <li class="products__item col-12 col-lg-4" v-for="i in perPage" :key="i">
                          <div class="card product w-100">
                            <div class="card-body">
                              <div class="d-flex flex-row">
                                <div class="flex-shrink-0">
                                  <div class="avatar-lg p-1">
                                    <span class="placeholder col-12 w-100 h-100"></span>
                                  </div>
                                </div>
                                <div class="flex-grow-1 ms-3">
                                  <h5 class="fs-15">
                                    <span class="placeholder w-100"></span>
                                    <span class="placeholder w-100"></span>
                                    <span class="placeholder w-75"></span>
                                  </h5>
                                  <ul class="list-inline text-muted">
                                    <li class="list-inline-item w-100">
                                      <span class="placeholder w-25"></span> <span class="placeholder w-50"></span>
                                    </li>
                                    <li class="list-inline-item w-100">
                                      <span class="placeholder w-25"></span> <span class="placeholder w-50"></span>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                            <div class="card-footer">
                              <div class="d-flex align-items-end align-content-center">
                                <div class="flex-grow-1">
                                  <div class="text-lg-center">
                                    <p class="text-muted mb-1"><span class="placeholder w-50"></span></p>
                                    <h5 class="fs-15">
                                      <span id="ticket_price" class="product-price"><span class="placeholder w-50"></span></span>
                                    </h5>
                                  </div>
                                </div>
                                <div class="flex-grow-1">
                                  <div class="text-lg-center">
                                    <p class="text-muted mb-1"><span class="placeholder w-50"></span></p>
                                    <h5 class="fs-15">
                                      <span id="ticket_price" class="product-price"><span class="placeholder w-50"></span></span>
                                    </h5>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="card-footer">
                              <ul class="list-inline text-muted">
                                <li class="list-inline-item w-100">
                                  <span class="placeholder col-5 float-start"></span> <span class="placeholder col-5 float-end"></span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </li>
                      </ul>
                  </div>
                </div>
              </div>
              <!-- end tab pane -->

              <div class="tab-pane" id="productnav-published" role="tabpanel">
                <div
                  id="table-product-list-published"
                  class="table-card gridjs-border-none"
                ></div>
              </div>
              <!-- end tab pane -->

              <div class="tab-pane" id="productnav-draft" role="tabpanel">
                <div class="py-4 text-center">
                  <div>
                    <lottie
                      class="avatar-xl"
                      colors="primary:#121331,secondary:#08a88a"
                      :options="defaultOptions"
                      :height="75"
                      :width="75"
                    />
                  </div>

                  <div class="mt-4">
                    <h5>Sorry! No Result Found</h5>
                  </div>
                </div>
              </div>
              <!-- end tab pane -->
            </div>
            <!-- end tab content -->
          </div>
          <div class="card-footer" style="padding: 0; margin: 0;" v-show="productsData.length > 0">
            <div class="d-flex justify-content-end m-3">
              <div class="pagination-wrap hstack gap-2">
                <a
                    class="page-item pagination-prev disabled"
                    href="javascript:;"
                    @click="page = 1"
                >
                  <i class="bx bx-chevrons-left"></i>
                </a>
                <a
                    class="page-item pagination-prev disabled"
                    href="javascript:;"
                    @click="page != 1 ? page-- : null"
                >
                  <i class="bx bx-chevron-left"></i>
                </a>
                <ul class="pagination listjs-pagination mb-0">
                  <li
                      v-for="(pageNumber, index) in pages.slice(
                        page - (page == 1 ? 1 : (page == pages.length) ? 3 : 2),
                        page + (page == 1 ? 2 : 1))"
                      :key="index"
                      @click="page = pageNumber"
                      :class="{
                        active: pageNumber == page,
                        disabled: pageNumber == '...',
                      }"
                  >
                    <a class="page" href="javascript:;">{{ pageNumber }}</a>
                  </li>
                </ul>
                <a
                    class="page-item pagination-next"
                    href="javascript:;"
                    @click="page != pages.length ? page++ : null"
                >
                  <i class="bx bx-chevron-right"></i>
                </a>
                <a
                    class="page-item pagination-next"
                    href="javascript:;"
                    @click="page = pages.length"
                >
                  <i class="bx bx-chevrons-right"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<style scoped>
.avatar-lg {
  min-height: 6rem;
}
.avatar-xs {
  height: 50px;
  width: 50px;
  object-fit: cover;
}
.avatar-lg img{
  height: 100%;
  width: auto;
  margin: 0 auto;
}

.products {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
}
.products__item {
  display: flex;
  padding: 0.5rem;
}
.products .card{
  margin: 0;
}
.liveProducts{
  word-break: break-word !important;
  white-space: break-spaces !important;
}
.app-search{
  padding: 0 !important;
}
</style>